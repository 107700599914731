import React, { useState } from 'react';
import { Button, FormLabel, IconButton, InputAdornment, Stack, TextField } from '@mui/material';

import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import { forgotPasswordAPI, loginAPI } from '@api/main';
import { FormControlWrapper, InputWrapper } from '@components/Input';
import AuthLayout from '@components/AuthLayout';
import { useDispatch } from 'react-redux';
import { loginAC } from 'src/redux/slice/authSlice';
import { BookingSteps } from '@utils/constants';
import { LoadingButton } from '@mui/lab';
import { signUpWithFirebase } from 'src/config/firebaseConfig';
import Iconify from '@components/Iconify';

const Login2 = ({ clinicInfo, authData, setAuthData, setCurrentStep }) => {
  const showToast = useToast();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleForgotPassword = async () => {
    try {
      setIsSubmitting(true);
      const res = await forgotPasswordAPI({ userName: authData.phoneNumber });
      const auth = await signUpWithFirebase(authData.phoneNumber);
      let errMessage = '';
      if (!auth?.errorCode) {
        setAuthData({ confirm: auth.confirm, userId: res.data.userId, isForgotPw: true });
        setCurrentStep(BookingSteps.register1);
      } else if (auth.errorCode === 'auth/too-many-requests') {
        errMessage = 'Bạn đã yêu cầu xác thực quá nhiều. Vui lòng thử lại sau!';
      } else if (auth.errorCode === 'auth/missing-client-identifier') {
        errMessage = 'Thiếu mã định danh. Vui lòng thử lại sau!';
      } else {
        errMessage = 'Xác thực thất bại';
      }
      if (!!errMessage) {
        showToast({
          type: 'error',
          message: errMessage
        });
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUsingOtherNumber = () => {
    setCurrentStep(BookingSteps.login1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const result = await loginAPI({
        userName: authData.phoneNumber,
        password
      });
      dispatch(loginAC(result.data));
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout clinicInfo={clinicInfo}>
      <form style={{ display: 'flex', flexDirection: 'column', height: '100%' }} onSubmit={onSubmit}>
        <FormControlWrapper required style={{ width: '100%', marginTop: '2rem' }}>
          <FormLabel>Mật khẩu</FormLabel>
          <InputWrapper hasBorder>
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              fullWidth
            />
          </InputWrapper>
        </FormControlWrapper>

        <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
          <Button size="small" color="error" variant="text" onClick={handleForgotPassword}>
            Quên mật khẩu
          </Button>
          <Button size="small" variant="text" onClick={handleUsingOtherNumber}>
            Số điện thoại khác
          </Button>
        </Stack>

        <LoadingButton
          id="recaptcha-container"
          sx={{ paddingY: '10px' }}
          variant="contained"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!password}
        >
          Tiếp theo
        </LoadingButton>
      </form>
    </AuthLayout>
  );
};

export default Login2;
