import React, { useState } from 'react';
import { FormLabel, IconButton, InputAdornment, TextField } from '@mui/material';

import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import { resetPasswordAPI, verifyPatientAPI } from '@api/main';
import { FormControlWrapper, InputWrapper } from '@components/Input';
import AuthLayout from '@components/AuthLayout';
import { useDispatch } from 'react-redux';
import { loginAC } from 'src/redux/slice/authSlice';
import { BookingSteps } from '@utils/constants';
import { LoadingButton } from '@mui/lab';
import Iconify from '@components/Iconify';

const Register2 = ({ clinicInfo, authData, setCurrentStep }) => {
  const showToast = useToast();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      let result = null;
      if (authData.isForgotPw) {
        result = await resetPasswordAPI({
          token: authData?.token,
          userId: authData?.userId,
          newPassword: password
        });
      } else {
        result = await verifyPatientAPI({
          token: authData?.token,
          userId: authData?.userId,
          password
        });
      }
      dispatch(loginAC(result.data));
      setCurrentStep(BookingSteps.selectClinic);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout clinicInfo={clinicInfo}>
      <form style={{ display: 'flex', flexDirection: 'column', height: '100%' }} onSubmit={onSubmit}>
        <FormControlWrapper required style={{ width: '100%', marginTop: '2rem' }}>
          <FormLabel>Mật khẩu</FormLabel>
          <InputWrapper hasBorder>
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              fullWidth
            />
          </InputWrapper>
        </FormControlWrapper>
        <FormControlWrapper required style={{ width: '100%', marginTop: '2rem' }}>
          <FormLabel>Nhập lại mật khẩu</FormLabel>
          <InputWrapper hasBorder>
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              fullWidth
            />
          </InputWrapper>
        </FormControlWrapper>

        <LoadingButton
          sx={{ marginTop: '2rem', paddingY: '10px' }}
          variant="contained"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!password || !confirmPassword || password !== confirmPassword}
        >
          Tiếp theo
        </LoadingButton>
      </form>
    </AuthLayout>
  );
};

export default Register2;
