import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAws5hRDvI8TsPpbGJri8k32mYZFmnZv5M',
  authDomain: 'drcloud-1ee63.firebaseapp.com',
  projectId: 'drcloud-1ee63',
  storageBucket: 'drcloud-1ee63.appspot.com',
  messagingSenderId: '739934592992',
  appId: '1:739934592992:web:9c93fbd752974546391585'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = 'vi';

const signUpWithFirebase = async (phoneNumber) => {
  try {
    const verify = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible'
    });

    const confirm = await signInWithPhoneNumber(auth, phoneNumber, verify);
    return { confirm };
  } catch (error) {
    return { errorCode: error.code };
  }
};

const confirmOtp = async (confirm, confirmationCode) => {
  try {
    await confirm.confirm(confirmationCode);
    const token = await auth.currentUser.getIdToken();
    return { token };
  } catch (error) {
    return { errorCode: error.code };
  }
};

export { signUpWithFirebase, confirmOtp };
