import React from 'react';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { createAppointment } from '@api/main';
import { BookingStepId } from '@utils/constants';
import NextStepLayoutWrapper from '@components/NextStepLayoutWrapper';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const ConfirmAppointment = ({ data, setCurrentStep }) => {
  const showToast = useToast();

  const listData = [
    {
      title: 'Phòng khám:',
      content: data.clinic.clinicName
    },
    {
      title: 'Địa chỉ:',
      content: data.clinic.address
    },
    {
      title: 'Chuyên khoa:',
      content: data.specialty
    },
    {
      title: 'Thời gian khám:',
      content: moment(data.startDateTimeUnix).format('HH:mm - DD/MM/YYYY')
    },
    {
      title: 'Bác sĩ khám:',
      content: data.doctorName
    },
    {
      title: 'divider',
      isDivider: true
    },
    {
      title: 'Bệnh nhân:',
      content: data.patient.fullName
    },
    {
      title: 'Năm sinh:',
      content: moment(data.patient.birthdayUnix).format('DD/MM/YYYY')
    },
    {
      title: 'Triệu chứng:',
      content: data.symptom
    }
  ];

  const nextStep = async () => {
    try {
      const payload = _.pick(data, [
        'patientId',
        'clinicId',
        'locationId',
        'doctorId',
        'scheduleId',
        'startDateTimeUnix',
        'symptom'
      ]);
      const result = await createAppointment(payload);
      if (!result?.metadata || !result?.metadata.success) return;
      setCurrentStep(BookingStepId.success);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <NextStepLayoutWrapper
      headerContent="Xác nhận lịch khám"
      prevStep={() => setCurrentStep(BookingStepId.createAppointment)}
      nextContent="Đặt Lịch"
      nextStep={nextStep}
      isNextStep
    >
      <Grid container flexDirection="column" padding="1rem" rowGap="1rem">
        {listData?.map((el) => (
          <Grid key={el.title} item borderBottom={el.isDivider && '1px solid'} borderColor="divider.main">
            {!el.isDivider && el.content && (
              <>
                <span style={{ fontWeight: '400', fontSize: '16px', fontFamily: 'Roboto' }}>{el.title}</span>
                &nbsp;
                <span style={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Roboto' }}>{el.content}</span>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </NextStepLayoutWrapper>
  );
};

export default ConfirmAppointment;
