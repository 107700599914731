import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { BookingStepId, createPatient } from '@utils/constants';
import { getAllPatientsAPI } from '@api/main';
import NextStepLayoutWrapper from '@components/NextStepLayoutWrapper';
import RadioSelect from '@components/RadioSelect';
import * as _ from 'lodash';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const SelectPatient = ({ data, setData, setCurrentStep }) => {
  const [patients, setPatients] = useState(null);
  const [patientId, setPatientId] = useState('');
  const showToast = useToast();

  const handleChange = (e) => {
    setPatientId(e.target.value);
  };

  useEffect(async () => {
    try {
      const res = await getAllPatientsAPI();
      setPatients(res);
      setPatientId(data?.patientId || res[0]?.patientId);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [data]);

  const nextStep = () => {
    if (!patientId) return;
    const nextId = patientId === createPatient ? BookingStepId.createPatient : BookingStepId.createAppointment;
    const patientSelected = patients.find((el) => el.patientId === patientId);
    const patient = _.pick(patientSelected, ['patientId', 'fullName', 'birthdayUnix']);
    setData((currentValue) => ({ ...currentValue, patientId, patient }));
    setCurrentStep(nextId);
  };

  return (
    <NextStepLayoutWrapper
      headerContent="Chọn hồ sơ khám"
      prevStep={() => setCurrentStep(BookingStepId.selectClinic)}
      nextStep={nextStep}
      isNextStep={!!patientId}
    >
      <FormControl>
        <RadioGroup name="patientId" value={patientId} onChange={handleChange}>
          {patients?.length > 0 && patients.map((el) => (
            <RadioSelect key={el.patientId} value={el} filedValue="patientId" currentShow="patient" />
          ))}
          {patients?.length === 0 && (
            <Box padding="1rem" borderBottom="1px solid" borderColor="divider.main">
              <Typography
                display="flex"
                paddingY="1rem"
                bgcolor="divider.main"
                justifyContent="center"
                alignItems="center"
                variant="h3"
                fontWeight="500"
                color="grey.700"
                borderRadius="10px"
              >
                Bạn chưa có hồ sơ nào
              </Typography>
            </Box>
          )}

          <Box padding="1em" borderBottom="1px solid" borderColor="divider.main">
            <Typography variant="button" color="textPrimary.main">
              Hoặc
            </Typography>
          </Box>
          <Box paddingX="1rem" paddingY="0.5rem" borderBottom="1px solid" borderColor="divider.main">
            <FormControlLabel
              value={createPatient}
              control={<Radio color="secondary" />}
              label={
                <Typography color="textSecondary.main" variant="h3">
                  Tạo hồ sơ mới
                </Typography>
              }
              style={{ width: '100%' }}
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </NextStepLayoutWrapper>
  );
};

export default SelectPatient;
