import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkRememberedUserAC } from 'src/redux/slice/authSlice';

// ** Yup intl message
import '../../config/yupConfig';

import { BookingSteps } from '@utils/constants';
import { getErrorMessage } from '@api/handleApiError';
import { getClinicInfoBySlug } from '@api/main';
import { useToast } from '@context/toastContext';
import Login from './containers/Login';
import Success from './containers/Success';
import SelectClinic from './containers/SelectClinic';
import SelectPatient from './containers/SelectPatient';
import CreatePatient from './containers/CreatePatient';
import CreateAppointment from './containers/CreateAppointment';
import ConfirmAppointment from './containers/ConfirmAppointment';
import Login2 from './containers/Login2';
import Register1 from './containers/Register1';
import Register2 from './containers/Register2';

const Booking = () => {
  const { clinicSlug } = useParams();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const showToast = useToast();

  const [clinicInfo, setClinicInfo] = useState({});
  const [data, setData] = useState({});
  const [authData, setAuthData] = useState({});
  const [currentStep, setCurrentStep] = useState(BookingSteps.login1);

  const fetchData = async (slug) => {
    try {
      const res = await getClinicInfoBySlug(slug);
      setClinicInfo(res.data);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  useEffect(() => {
    fetchData(clinicSlug);
  }, [clinicSlug]);

  // useEffect(() => {
  //   const checkRemenberedUser = async () => {
  //     dispatch(checkRememberedUserAC());
  //   };
  //   checkRemenberedUser();
  // }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      setCurrentStep(BookingSteps.selectClinic);
    } else {
      setCurrentStep(BookingSteps.login1);
    }
  }, [isLoggedIn]);

  const renderContent = useMemo(() => {
    switch (currentStep) {
      case BookingSteps.login1:
        return <Login clinicInfo={clinicInfo} setCurrentStep={setCurrentStep} setAuthData={setAuthData} />;
      case BookingSteps.login2:
        return (
          <Login2
            clinicInfo={clinicInfo}
            authData={authData}
            setAuthData={setAuthData}
            setCurrentStep={setCurrentStep}
          />
        );
      case BookingSteps.register1:
        return (
          <Register1
            clinicInfo={clinicInfo}
            authData={authData}
            setCurrentStep={setCurrentStep}
            setAuthData={setAuthData}
          />
        );
      case BookingSteps.register2:
        return <Register2 clinicInfo={clinicInfo} authData={authData} setCurrentStep={setCurrentStep} />;
      case BookingSteps.selectClinic:
        return <SelectClinic data={data} clinicSlug={clinicSlug} setData={setData} setCurrentStep={setCurrentStep} />;
      case BookingSteps.selectPatient:
        return <SelectPatient data={data} setData={setData} setCurrentStep={setCurrentStep} />;

      case BookingSteps.createPatient:
        return <CreatePatient data={data} setData={setData} setCurrentStep={setCurrentStep} />;

      case BookingSteps.createAppointment:
        return <CreateAppointment data={data} setData={setData} setCurrentStep={setCurrentStep} />;

      case BookingSteps.confirmAppointment:
        return <ConfirmAppointment data={data} setData={setData} setCurrentStep={setCurrentStep} />;

      case BookingSteps.success:
        return <Success setCurrentStep={setCurrentStep} />;
      default:
        break;
    }
    return <></>;
  }, [currentStep, data, isLoggedIn, clinicInfo, clinicSlug, authData]);

  return <>{renderContent}</>;
};

export default Booking;
