// Known error code
export const ERROR_CODES = {
  LoginFailed: 'PINT_1001',
  TokenError: 'PINT_1004',
  WrongPassword: 'PINT_1011',
  ClinicNotFound: 'PINT_4002',
  DoctorNotFound: 'PINT_4004',
  LocationNotFound: 'PINT_4006',
  PatientNotFound: 'PINT_4007',
  InvalidPw: 'PINT_5007'
};

// All api error
export const API_ERRORS = [
  {
    PINT_5007: {
      message: 'LoginFailed'
    }
  }
];

export const getErrorMessage = (error, defaultMessage = 'Lỗi không xác định, vui lòng liên hệ hỗ trợ kỹ thuật') => {
  if (error.httpStatusCode === 403) {
    return 'Bạn không có quyền truy cập';
  }
  if (error?.response?.metadata) {
    return error.response.metadata.messages[0].value;
  }
  return error.message || defaultMessage;
};
