import Booking from '@views/Booking';
import Home from '@views/Home';
import RedirectFromGateway from '@views/Payment/RedirectFromGateway';
import Page404 from '@views/misc/Page404';
import { Navigate } from 'react-router';

const routes = [
  {
    path: '/mb',
    element: <Home />
  },
  {
    path: '/:clinicSlug',
    element: <Booking />
  },
  {
    path: 'payment/redirect-from-gateway',
    element: <RedirectFromGateway />
  },
  {
    path: '404',
    element: <Page404 />
  },
  { path: '*', element: <Navigate to="/404" replace /> }
];

export default routes;
