export const BookingStepId = {
  welcome: 'welcome',
  selectClinic: 'selectClinic',
  selectPatient: 'selectPatient',
  createPatient: 'createPatient',
  createAppointment: 'createAppointment',
  confirmAppointment: 'confirmAppointment',
  success: 'success'
};

export const BookingSteps = {
  login1: 'login1',
  login2: 'login2',
  register1: 'register1',
  register2: 'register2',
  selectClinic: 'selectClinic',
  selectPatient: 'selectPatient',
  createPatient: 'createPatient',
  createAppointment: 'createAppointment',
  confirmAppointment: 'confirmAppointment',
  success: 'success'
};

export const createPatient = 'createPatient';

export const GenderEnum = {
  Nam: 0,
  Nữ: 1
};

export const CurrencyUnitEnum = {
  VND: 'VND'
};

export const CountryEnum = {
  'Việt Nam': 192,
  Other: 0
};

export const SpecialtyDefault = {
  specialtyId: 'all',
  specialtyName: 'Tất cả'
};
