import { Box } from '@mui/material';

const svg = (
  <svg width="303" height="226" viewBox="0 0 303 226" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M303 198.801H0V198.95H303V198.801Z" fill="#EBEBEB" />
    <path d="M51.8617 204.487H31.791V204.635H51.8617V204.487Z" fill="#EBEBEB" />
    <path d="M108.975 202.868H88.0684V203.017H108.975V202.868Z" fill="#EBEBEB" />
    <path d="M79.69 207.371H46.1055V207.52H79.69V207.371Z" fill="#EBEBEB" />
    <path d="M271.991 207.92H245.818V208.069H271.991V207.92Z" fill="#EBEBEB" />
    <path d="M238.358 204.188H224.48V204.337H238.358V204.188Z" fill="#EBEBEB" />
    <path d="M175.323 206.583H118.553V206.732H175.323V206.583Z" fill="#EBEBEB" />
    <path
      d="M143.623 172.167H26.6105C25.6938 172.165 24.8152 171.806 24.1676 171.166C23.52 170.527 23.1562 169.661 23.1563 168.757V6.66453C23.1642 5.76638 23.5315 4.90761 24.1782 4.27474C24.825 3.64187 25.699 3.28602 26.6105 3.28448H143.623C144.541 3.28448 145.421 3.64374 146.07 4.28322C146.719 4.9227 147.083 5.79002 147.083 6.69438V168.757C147.083 169.662 146.719 170.529 146.07 171.168C145.421 171.808 144.541 172.167 143.623 172.167ZM26.6105 3.40392C25.734 3.4055 24.8939 3.74973 24.2747 4.36104C23.6555 4.97235 23.3077 5.80079 23.3077 6.66453V168.757C23.3077 169.621 23.6555 170.449 24.2747 171.061C24.8939 171.672 25.734 172.016 26.6105 172.018H143.623C144.5 172.016 145.341 171.672 145.961 171.061C146.581 170.45 146.93 169.621 146.932 168.757V6.66453C146.93 5.80024 146.581 4.97181 145.961 4.36067C145.341 3.74953 144.5 3.4055 143.623 3.40392H26.6105Z"
      fill="#EBEBEB"
    />
    <path
      d="M274.707 172.167H157.689C156.772 172.166 155.892 171.806 155.244 171.167C154.595 170.527 154.23 169.661 154.229 168.757V6.66453C154.238 5.76584 154.607 4.90707 155.254 4.27437C155.902 3.64166 156.777 3.28601 157.689 3.28448H274.707C275.618 3.28759 276.49 3.64414 277.136 4.27684C277.781 4.90954 278.148 5.76741 278.156 6.66453V168.757C278.156 169.659 277.793 170.525 277.146 171.164C276.5 171.803 275.623 172.164 274.707 172.167ZM157.689 3.40392C156.812 3.4055 155.971 3.74953 155.351 4.36067C154.731 4.97181 154.382 5.80024 154.38 6.66453V168.757C154.382 169.621 154.731 170.45 155.351 171.061C155.971 171.672 156.812 172.016 157.689 172.018H274.707C275.584 172.016 276.425 171.672 277.045 171.061C277.665 170.45 278.015 169.621 278.016 168.757V6.66453C278.015 5.80024 277.665 4.97181 277.045 4.36067C276.425 3.74953 275.584 3.4055 274.707 3.40392H157.689Z"
      fill="#EBEBEB"
    />
    <path d="M188.926 120.684H171.752V194.078H188.926V120.684Z" fill="#F0F0F0" />
    <path d="M259.737 194.078H175.049V198.73H259.737V194.078Z" fill="#F0F0F0" />
    <path d="M188.925 194.078H263.033V120.684L188.925 120.684V194.078Z" fill="#F5F5F5" />
    <path d="M195.677 153.786H256.283V129.714H195.677V153.786Z" fill="#F0F0F0" />
    <path d="M195.677 185.048H256.283V160.976H195.677V185.048Z" fill="#F0F0F0" />
    <path
      d="M232.783 131.75H219.179C218.734 131.748 218.308 131.574 217.993 131.264C217.679 130.954 217.502 130.534 217.5 130.096C217.502 129.658 217.679 129.239 217.994 128.93C218.308 128.621 218.734 128.448 219.179 128.448H232.783C233.227 128.448 233.654 128.621 233.968 128.93C234.283 129.239 234.46 129.658 234.462 130.096C234.46 130.534 234.283 130.954 233.969 131.264C233.654 131.574 233.228 131.748 232.783 131.75Z"
      fill="#F5F5F5"
    />
    <path
      d="M232.783 163H219.179C218.734 163 218.308 162.827 217.994 162.518C217.679 162.209 217.502 161.79 217.5 161.352C217.502 160.914 217.679 160.495 217.994 160.186C218.308 159.877 218.734 159.704 219.179 159.704H232.783C233.227 159.704 233.654 159.877 233.968 160.186C234.283 160.495 234.46 160.914 234.462 161.352C234.46 161.79 234.283 162.209 233.968 162.518C233.654 162.827 233.227 163 232.783 163Z"
      fill="#F5F5F5"
    />
    <path d="M225.614 19.4561H194.732V52.6474H225.614V19.4561Z" fill="#E0E0E0" />
    <path d="M227.461 19.4561H195.773V52.6474H227.461V19.4561Z" fill="#F5F5F5" />
    <path d="M224.801 50.0318V22.0718H198.428V50.0318H224.801Z" fill="white" />
    <path
      d="M214.846 41.0383L217.973 35.6995C218.301 35.1413 218.474 34.508 218.474 33.8632C218.475 33.2184 218.303 32.5848 217.976 32.0261C217.649 31.4675 217.179 31.0034 216.613 30.6807C216.046 30.3579 215.403 30.1878 214.749 30.1875H208.465C207.811 30.1886 207.168 30.3591 206.602 30.6818C206.036 31.0046 205.566 31.4683 205.239 32.0265C204.911 32.5848 204.739 33.2179 204.738 33.8625C204.738 34.5071 204.909 35.1406 205.235 35.6995L208.368 41.0383C208.693 41.6019 209.165 42.0706 209.734 42.3966C210.302 42.7226 210.949 42.8943 211.607 42.8943C212.265 42.8943 212.912 42.7226 213.48 42.3966C214.049 42.0706 214.521 41.6019 214.846 41.0383Z"
      fill="#F5F5F5"
    />
    <path d="M76.5259 77.2572H57.4551V198.801H76.5259V77.2572Z" fill="#E0E0E0" />
    <path d="M125.127 198.801H61.2729L54.5645 49.2017H118.419L125.127 198.801Z" fill="#E6E6E6" />
    <path d="M134.439 198.801H63.81L57.1016 49.2017H127.731L134.439 198.801Z" fill="#F0F0F0" />
    <path d="M70.7262 191.468L65.0723 56.5351H120.818L126.472 191.468H70.7262Z" fill="white" />
    <path d="M94.6762 191.468L75.7266 56.5351H81.6714L100.621 191.468H94.6762Z" fill="#FAFAFA" />
    <path d="M103.141 191.468L84.1914 56.5351H94.3298L113.279 191.468H103.141Z" fill="#FAFAFA" />
    <path
      d="M229.492 117.657H230.885C230.885 114.826 230.885 107.642 231.031 91.1775C231.279 61.3185 231.764 59.527 231.764 59.527L228.461 73.298C226.334 88.5499 229.067 103.88 229.492 117.657Z"
      fill="#E6E6E6"
    />
    <path
      d="M226.737 71.2436C221.955 65.6301 211.738 63.9938 201.285 62.5964C197.182 62.047 212.077 71.2913 219.355 77.8603C225.015 82.9602 228.627 84.3696 230.179 89.4516C230.985 83.1155 230.851 76.0807 226.737 71.2436Z"
      fill="#E6E6E6"
    />
    <path
      d="M233.073 81.2763C237.855 75.6628 248.066 74.0265 258.525 72.6291C262.634 72.0857 247.739 81.33 240.455 87.893C234.801 92.9989 231.183 94.4083 229.631 99.4903C228.825 93.1482 228.953 86.1134 233.073 81.2763Z"
      fill="#E6E6E6"
    />
    <path
      d="M231.024 58.2251C234.612 51.5128 243.629 45.1946 253.749 42.7999C257.725 41.8564 244.544 55.1914 239.539 63.7908C235.648 70.4434 230.485 78.2904 229.673 83.9039C228.461 72.7605 227.443 64.9135 231.024 58.2251Z"
      fill="#E6E6E6"
    />
    <path
      d="M230.602 120.875C226.36 120.875 222.803 118.767 222.561 116.098L221.852 111.488H239.371L238.662 116.098C238.401 118.767 234.875 120.875 230.602 120.875Z"
      fill="#F0F0F0"
    />
    <path d="M240.492 113.231H220.712L220.148 108.096H241.062L240.492 113.231Z" fill="#F0F0F0" />
    <path
      d="M151.499 225.77C216.391 225.77 268.997 222.743 268.997 219.01C268.997 215.276 216.391 212.25 151.499 212.25C86.6073 212.25 34.002 215.276 34.002 219.01C34.002 222.743 86.6073 225.77 151.499 225.77Z"
      fill="#F5F5F5"
    />
    <path
      d="M170.237 62.7039C170.237 46.0738 163.533 30.1248 151.6 18.3656C139.667 6.60629 123.482 0 106.607 0C89.7308 0 73.5463 6.60629 61.6134 18.3656C49.6804 30.1248 42.9766 46.0738 42.9766 62.7039C42.9766 89.8935 60.0173 112.121 85.0875 121.729L128.81 138.45L120.514 123.915C134.625 120.795 147.237 113.033 156.275 101.907C165.312 90.7816 170.236 76.9551 170.237 62.7039Z"
      fill="#4A98E2"
    />
    <path
      d="M105.183 119.735C93.457 119.733 81.9943 116.306 72.2448 109.885C62.4952 103.464 54.8967 94.3383 50.41 83.6619C45.9233 72.9854 44.75 61.2377 47.0385 49.9041C49.3269 38.5706 54.9743 28.1602 63.2666 19.9895C71.5588 11.8188 82.1235 6.25464 93.6246 4.00066C105.126 1.74668 117.047 2.9041 127.881 7.32656C138.714 11.749 147.974 19.2379 154.489 28.8462C161.003 38.4546 164.481 49.7508 164.481 61.3065C164.463 76.7984 158.21 91.6506 147.093 102.604C135.976 113.558 120.904 119.719 105.183 119.735ZM105.183 3.47559C93.5769 3.47677 82.2313 6.8695 72.5814 13.2248C62.9315 19.58 55.4107 28.6124 50.9699 39.1797C46.5291 49.7471 45.3678 61.3748 47.6328 72.5925C49.8979 83.8102 55.4876 94.1141 63.6951 102.201C71.9025 110.289 82.3592 115.796 93.7428 118.027C105.126 120.258 116.926 119.112 127.649 114.735C138.372 110.358 147.537 102.945 153.985 93.4351C160.433 83.925 163.875 72.7442 163.875 61.3065C163.849 45.9756 157.657 31.2798 146.656 20.4397C135.654 9.59958 120.741 3.49928 105.183 3.47559Z"
      fill="white"
    />
    <path
      d="M63.5801 65.5525C63.5801 57.4547 65.2506 50.9713 68.5917 46.1023C70.1278 43.7681 72.1848 41.8106 74.6056 40.3792C77.0264 38.9479 79.7471 38.0805 82.56 37.8433C88.6725 37.1904 93.3852 38.9043 96.698 42.985C100.011 47.0658 101.665 53.0973 101.661 61.0796C101.661 66.8802 100.938 71.7114 99.4916 75.5732C98.2395 79.1558 96.0832 82.3658 93.2256 84.9011C90.3486 87.3153 86.7746 88.7775 83.0084 89.0814C78.8634 89.5233 75.4294 88.9978 72.7064 87.5048C69.9835 86.0119 67.7817 83.4241 66.101 79.7415C64.4204 76.0788 63.5801 71.3491 63.5801 65.5525ZM74.9365 64.4059C74.9365 69.4182 75.6253 72.9436 77.003 74.982C77.5685 75.9261 78.4047 76.6844 79.4064 77.1615C80.4081 77.6386 81.5306 77.8132 82.6327 77.6633C83.8251 77.5406 84.9677 77.1268 85.957 76.4595C86.9463 75.7922 87.7512 74.8923 88.2988 73.8413C89.628 71.5521 90.2926 67.6346 90.2926 62.0888C90.2926 57.4189 89.5957 54.0826 88.2019 52.0801C87.6176 51.1437 86.7682 50.396 85.7582 49.9291C84.7483 49.4623 83.6218 49.2966 82.5176 49.4525C80.2309 49.6993 78.3927 50.9892 77.003 53.3222C75.6132 55.6552 74.9244 59.3498 74.9365 64.4059Z"
      fill="white"
    />
    <path
      d="M107.83 35.992L119.187 34.7977V53.5432L131.082 33.5317L146.19 31.9133L132.779 52.0681L146.79 81.4554L132.803 82.9483L125.053 63.3787L119.187 72.3006V84.3816L107.83 85.5759V35.992Z"
      fill="white"
    />
    <path
      d="M51.8838 14.9056C50.969 14.9068 50.0744 14.6406 49.3133 14.1406C48.5521 13.6406 47.9585 12.9294 47.6076 12.097C47.2567 11.2645 47.1643 10.3481 47.342 9.46386C47.5197 8.57959 47.9596 7.76715 48.606 7.12931C49.2524 6.49148 50.0763 6.05692 50.9734 5.88062C51.8705 5.70433 52.8005 5.79421 53.6457 6.1389C54.491 6.4836 55.2135 7.06761 55.7218 7.81705C56.2301 8.56649 56.5015 9.44768 56.5015 10.3491C56.4999 11.5561 56.013 12.7132 55.1476 13.5672C54.2821 14.4212 53.1085 14.9024 51.8838 14.9056ZM51.8838 6.39578C51.0886 6.3946 50.311 6.62596 49.6493 7.06056C48.9877 7.49516 48.4718 8.11346 48.1669 8.83717C47.8621 9.56088 47.782 10.3575 47.9368 11.126C48.0917 11.8946 48.4744 12.6007 49.0367 13.1547C49.599 13.7088 50.3154 14.086 51.0953 14.2386C51.8753 14.3912 52.6836 14.3123 53.418 14.0119C54.1524 13.7115 54.7799 13.2031 55.2209 12.551C55.6619 11.899 55.8967 11.1327 55.8955 10.3491C55.8939 9.30111 55.4707 8.29649 54.7187 7.55543C53.9667 6.81438 52.9472 6.39736 51.8838 6.39578Z"
      fill="#4A98E2"
    />
    <path
      d="M96.8328 136.217C96.2213 136.217 95.6235 136.038 95.1151 135.703C94.6067 135.368 94.2106 134.892 93.9769 134.335C93.7431 133.779 93.6823 133.166 93.802 132.575C93.9218 131.984 94.2167 131.441 94.6495 131.016C95.0824 130.59 95.6336 130.3 96.2336 130.183C96.8335 130.067 97.4551 130.128 98.0197 130.359C98.5843 130.591 99.0666 130.982 99.4055 131.484C99.7443 131.985 99.9246 132.575 99.9234 133.177C99.9218 133.984 99.5955 134.757 99.016 135.327C98.4366 135.897 97.6514 136.217 96.8328 136.217ZM96.8328 130.729C96.3414 130.729 95.861 130.872 95.4524 131.141C95.0438 131.41 94.7254 131.793 94.5373 132.24C94.3493 132.688 94.3001 133.18 94.3959 133.655C94.4918 134.13 94.7284 134.566 95.0759 134.909C95.4234 135.251 95.8661 135.484 96.3481 135.579C96.83 135.673 97.3296 135.625 97.7836 135.439C98.2376 135.254 98.6256 134.94 98.8986 134.537C99.1717 134.135 99.3174 133.661 99.3174 133.177C99.3174 132.528 99.0556 131.905 98.5897 131.446C98.1237 130.987 97.4917 130.729 96.8328 130.729Z"
      fill="#4A98E2"
    />
    <path
      d="M154.42 13.5083C154.42 13.7261 154.354 13.939 154.231 14.12C154.108 14.3009 153.933 14.4417 153.728 14.5245C153.524 14.6073 153.299 14.6284 153.082 14.585C152.866 14.5416 152.667 14.4358 152.512 14.2809C152.356 14.1261 152.251 13.9292 152.209 13.7152C152.168 13.5013 152.192 13.28 152.278 13.0794C152.364 12.8789 152.509 12.7081 152.694 12.5888C152.879 12.4695 153.096 12.4071 153.317 12.4095C153.61 12.4126 153.891 12.5298 154.097 12.7355C154.304 12.9412 154.42 13.2189 154.42 13.5083Z"
      fill="#4A98E2"
    />
    <path
      d="M85.5777 132.078C85.5777 132.296 85.5122 132.509 85.3894 132.689C85.2666 132.87 85.0921 133.011 84.888 133.094C84.6839 133.177 84.4594 133.198 84.2429 133.156C84.0265 133.113 83.8278 133.007 83.6721 132.853C83.5164 132.699 83.4107 132.503 83.3683 132.289C83.326 132.075 83.349 131.854 83.4343 131.654C83.5196 131.453 83.6635 131.282 83.8476 131.162C84.0318 131.042 84.248 130.978 84.4688 130.98C84.7634 130.981 85.0455 131.098 85.2533 131.303C85.4611 131.509 85.5777 131.788 85.5777 132.078Z"
      fill="#4A98E2"
    />
    <path d="M199.021 201.118L202.67 215.188L209.511 213.534L205.857 199.458L199.021 201.118Z" fill="#FF8B7B" />
    <path
      d="M209.677 210.661L201.926 212.298C201.805 212.321 201.698 212.388 201.625 212.485C201.552 212.582 201.519 212.703 201.532 212.823L202.065 218.413C202.082 218.553 202.129 218.689 202.202 218.81C202.275 218.932 202.374 219.037 202.491 219.12C202.608 219.202 202.741 219.259 202.881 219.287C203.022 219.316 203.167 219.315 203.308 219.285C205.732 218.687 206.465 218.365 209.531 217.708C213.119 216.932 214.379 217.141 218.621 216.233C221.166 215.684 221.045 213.056 219.912 213.05C216.896 213.054 213.932 212.276 211.319 210.793C210.809 210.543 210.221 210.496 209.677 210.661Z"
      fill="#263238"
    />
    <path
      opacity="0.2"
      d="M199.016 201.124L200.9 208.374L207.742 206.72L205.857 199.464L199.016 201.124Z"
      fill="black"
    />
    <path
      d="M186.829 146.918C178.684 123.3 172.23 95.4413 172.23 95.4413L195.471 93.9961C195.471 93.9961 198.894 130.161 200.682 146.835C202.537 164.153 208.312 203.298 208.312 203.298L197.828 205.394C197.828 205.394 187.993 168.53 186.829 146.918Z"
      fill="#4A98E2"
    />
    <path
      opacity="0.2"
      d="M182.952 108.567L177.311 115.441C178.747 120.744 180.431 126.704 182.274 132.801C183.333 124.767 183.56 116.647 182.952 108.567Z"
      fill="black"
    />
    <path
      d="M208.156 204.749L198.544 206.827C198.353 206.867 198.153 206.837 197.983 206.743C197.812 206.648 197.682 206.496 197.617 206.314L196.557 203.256C196.522 203.153 196.509 203.043 196.519 202.935C196.529 202.826 196.562 202.721 196.616 202.626C196.669 202.53 196.742 202.447 196.83 202.381C196.918 202.314 197.019 202.267 197.126 202.241L207.562 199.823C207.674 199.796 207.791 199.794 207.904 199.817C208.017 199.84 208.124 199.887 208.217 199.955C208.31 200.022 208.387 200.109 208.442 200.209C208.497 200.309 208.53 200.42 208.537 200.533L208.78 203.931C208.794 204.119 208.739 204.305 208.624 204.456C208.509 204.606 208.343 204.711 208.156 204.749Z"
      fill="#263238"
    />
    <path
      opacity="0.1"
      d="M208.156 204.749L198.544 206.827C198.353 206.867 198.153 206.837 197.983 206.743C197.812 206.648 197.682 206.496 197.617 206.314L196.557 203.256C196.522 203.153 196.509 203.043 196.519 202.935C196.529 202.826 196.562 202.721 196.616 202.626C196.669 202.53 196.742 202.447 196.83 202.381C196.918 202.314 197.019 202.267 197.126 202.241L207.562 199.823C207.674 199.796 207.791 199.794 207.904 199.817C208.017 199.84 208.124 199.887 208.217 199.955C208.31 200.022 208.387 200.109 208.442 200.209C208.497 200.309 208.53 200.42 208.537 200.533L208.78 203.931C208.794 204.119 208.739 204.305 208.624 204.456C208.509 204.606 208.343 204.711 208.156 204.749Z"
      fill="white"
    />
    <path d="M160.686 200.629L160.256 215.146L167.304 215.051L167.734 200.533L160.686 200.629Z" fill="#FF8B7B" />
    <path
      d="M167.473 212.071L159.983 212.178C159.86 212.177 159.742 212.222 159.652 212.302C159.561 212.383 159.504 212.494 159.492 212.614L158.886 218.198C158.875 218.339 158.894 218.48 158.941 218.614C158.989 218.747 159.064 218.869 159.162 218.973C159.26 219.076 159.379 219.158 159.511 219.214C159.642 219.27 159.785 219.298 159.928 219.296C162.419 219.219 163.207 219.016 166.346 218.968C170.018 218.908 170.812 219.362 175.114 219.296C177.72 219.255 178.144 216.657 177.023 216.43C174.073 215.84 171.332 214.497 169.073 212.536C168.62 212.184 168.047 212.017 167.473 212.071Z"
      fill="#263238"
    />
    <path
      opacity="0.2"
      d="M160.687 200.635L160.463 208.117L167.517 208.016L167.735 200.533L160.687 200.635Z"
      fill="black"
    />
    <path
      d="M162.444 146.44C164.432 121.592 162.753 108.191 165.68 95.8473L186.829 94.5574C186.829 94.5574 180.266 131.081 176.637 147.599C173.007 164.117 169.116 204.242 169.116 204.242L158.723 204.367C158.723 204.367 157.451 165.825 162.444 146.44Z"
      fill="#4A98E2"
    />
    <path
      d="M169.074 206.081L159.244 206.23C159.04 206.232 158.842 206.158 158.691 206.021C158.54 205.885 158.448 205.697 158.432 205.496L158.22 202.295C158.214 202.19 158.229 202.085 158.264 201.985C158.299 201.886 158.354 201.795 158.426 201.717C158.498 201.639 158.585 201.576 158.682 201.532C158.778 201.487 158.883 201.463 158.99 201.459L169.698 201.13C169.818 201.127 169.936 201.151 170.045 201.199C170.155 201.247 170.252 201.319 170.329 201.409C170.406 201.499 170.462 201.605 170.492 201.719C170.523 201.833 170.527 201.952 170.504 202.068L169.843 205.442C169.81 205.62 169.715 205.78 169.574 205.897C169.434 206.013 169.257 206.078 169.074 206.081Z"
      fill="#263238"
    />
    <path
      opacity="0.1"
      d="M169.074 206.081L159.244 206.23C159.04 206.232 158.842 206.158 158.691 206.021C158.54 205.885 158.448 205.697 158.432 205.496L158.22 202.295C158.214 202.19 158.229 202.085 158.264 201.985C158.299 201.886 158.354 201.795 158.426 201.717C158.498 201.639 158.585 201.576 158.682 201.532C158.778 201.487 158.883 201.463 158.99 201.459L169.698 201.13C169.818 201.127 169.936 201.151 170.045 201.199C170.155 201.247 170.252 201.319 170.329 201.409C170.406 201.499 170.462 201.605 170.492 201.719C170.523 201.833 170.527 201.952 170.504 202.068L169.843 205.442C169.81 205.62 169.715 205.78 169.574 205.897C169.434 206.013 169.257 206.078 169.074 206.081Z"
      fill="white"
    />
    <path
      d="M195.004 52.4622L196.216 54.421L197.555 56.4335C198.422 57.7532 199.325 59.0431 200.228 60.3032C201.131 61.5632 202.088 62.7576 203.052 63.8863C203.908 64.892 204.866 65.8085 205.912 66.6214L202.949 66.1854C204.151 65.9417 205.327 65.5817 206.457 65.1105C207.7 64.588 208.913 64.002 210.093 63.3548C211.305 62.7039 212.517 61.9813 213.729 61.2288C214.941 60.4764 216.153 59.6582 217.299 58.8938L217.396 58.8341C217.81 58.547 218.316 58.4161 218.82 58.4653C219.325 58.5145 219.794 58.7406 220.144 59.1023C220.493 59.464 220.699 59.9373 220.724 60.4361C220.749 60.9349 220.592 61.426 220.28 61.82C219.329 63.0797 218.294 64.2766 217.184 65.4031C216.081 66.5294 214.914 67.5922 213.687 68.5861C212.419 69.6121 211.077 70.5443 209.669 71.3749C208.159 72.2759 206.546 72.9975 204.864 73.5248C204.411 73.6619 203.935 73.7056 203.465 73.6532C202.995 73.6008 202.54 73.4534 202.131 73.2202L201.9 73.0888C200.162 72.0676 198.569 70.8246 197.161 69.3923C196.503 68.7394 195.871 68.0785 195.265 67.4096C194.659 66.7408 194.083 66.058 193.538 65.3613C192.423 63.9878 191.398 62.5785 190.423 61.1392C189.938 60.4226 189.459 59.7 189.005 58.9595C188.55 58.219 188.114 57.5024 187.653 56.6783C187.215 55.7376 187.146 54.67 187.46 53.6821C187.774 52.6942 188.449 51.8564 189.354 51.3308C190.259 50.8053 191.33 50.6295 192.36 50.8374C193.389 51.0453 194.303 51.6221 194.925 52.4562L195.004 52.4622Z"
      fill="#FF8B7B"
    />
    <path
      d="M185.817 44.4898C194.028 45.4095 200.239 57.9383 200.239 57.9383L191.755 66.8184C191.755 66.8184 187.986 62.3873 183.932 56.7798C179.526 50.6886 179.32 43.7673 185.817 44.4898Z"
      fill="#263238"
    />
    <path
      d="M185.168 46.3829C182.011 45.2781 172.121 46.9323 172.121 46.9323C174.891 50.4258 176.733 79.3652 176.969 96.0086C179.969 96.4147 187.095 96.3609 189.768 95.7518C189.435 89.2187 187.253 57.9981 185.168 46.3829Z"
      fill="white"
    />
    <path
      d="M195.957 55.1495C195.584 52.1396 194.104 49.3692 191.796 47.3636C189.488 45.358 186.514 44.2568 183.437 44.2689C177.528 44.3175 171.64 44.9578 165.863 46.1799C164.966 46.3713 164.118 46.7372 163.366 47.2562C162.615 47.7752 161.977 48.4368 161.489 49.2022C161 49.9676 160.672 50.8214 160.523 51.7134C160.374 52.6055 160.407 53.5179 160.621 54.3971C164.972 72.3901 163.827 89.4575 164.99 97.3941C169.732 98.5579 174.606 99.1099 179.492 99.0363C179.492 99.0363 179.316 96.8267 179.098 93.2496C179.019 91.8522 177.195 49.966 174.353 46.365C174.353 46.365 180.449 45.7678 183.607 46.8547C185.952 59.8971 188.146 99.0542 188.146 99.0602C193.133 98.7079 196.63 95.8414 196.563 95.0233C197.557 79.0128 197.072 64.5193 195.957 55.1495Z"
      fill="#263238"
    />
    <path
      d="M171.104 31.6385C172.031 35.1559 173.115 41.3427 171.413 44.7227C171.238 45.0522 171.185 45.4311 171.263 45.7948C171.34 46.1585 171.543 46.4845 171.837 46.7173C174.024 48.3835 176.836 49.9301 180.927 49.7032C185.223 49.4464 184.902 46.9144 183.811 45.2244C183.7 45.0454 183.553 44.8906 183.379 44.7694C183.205 44.6483 183.008 44.5633 182.799 44.5197C178.739 43.6956 178.691 40.5126 179.272 37.4551L171.104 31.6385Z"
      fill="#FF8B7B"
    />
    <path
      opacity="0.2"
      d="M174.43 34.0034L179.278 37.4313C179.126 38.1708 179.041 38.9222 179.024 39.6767C177.273 39.599 174.782 37.9508 174.479 36.1772C174.33 35.4614 174.314 34.725 174.43 34.0034Z"
      fill="black"
    />
    <path
      d="M184.508 27.1716C185.605 26.455 186.186 21.5163 184.508 19.9159C186.241 18.411 185.241 15.0608 182.865 14.4935C180.49 13.9262 178.745 15.4669 175.684 13.532C175.157 14.2367 175.46 15.1385 176.375 15.664C177.696 16.4224 184.508 27.1716 184.508 27.1716Z"
      fill="#263238"
    />
    <path
      d="M169.613 25.6667C170.437 31.0413 170.637 34.2601 173.667 36.7802C178.231 40.5723 184.806 37.4371 185.636 31.8953C186.387 26.9089 184.915 18.9246 179.334 17.2763C178.097 16.9117 176.786 16.8624 175.524 17.1331C174.263 17.4037 173.091 17.9855 172.12 18.8239C171.149 19.6623 170.409 20.7298 169.971 21.9266C169.532 23.1234 169.409 24.4103 169.613 25.6667Z"
      fill="#FF8B7B"
    />
    <path
      d="M171.285 30.2292C172.746 29.5544 171.946 23.7856 171.588 22.651C175.424 23.1765 186.211 20.3399 182.66 16.2074C180.066 13.2215 172.497 16.6374 169.273 14.0635C168.316 15.0728 169.649 16.2671 169.649 16.2671C169.649 16.2671 164.528 17.4615 166.619 21.0446C162.462 20.8594 165.31 30.8264 171.285 30.2292Z"
      fill="#263238"
    />
    <path
      d="M167.583 18.7634C165.565 20.322 164.771 19.6233 163.602 18.5723C163.323 21.6358 165.796 22.0299 166.941 21.7134C167.692 20.7579 167.583 18.7634 167.583 18.7634Z"
      fill="#263238"
    />
    <path
      d="M167.819 30.6233C168.494 31.7457 169.577 32.5722 170.849 32.9344C172.455 33.3465 173.061 31.74 172.449 30.1575C171.897 28.7064 170.395 26.7476 168.861 27.0522C167.328 27.3567 166.946 29.202 167.819 30.6233Z"
      fill="#FF8B7B"
    />
    <path
      d="M177.141 26.1445C177.184 26.5864 177.45 26.9209 177.747 26.891C178.044 26.8611 178.244 26.4849 178.202 26.043C178.159 25.6011 177.893 25.2667 177.596 25.2965C177.299 25.3264 177.105 25.7026 177.141 26.1445Z"
      fill="#263238"
    />
    <path
      d="M182.186 25.2906C182.228 25.7325 182.495 26.0669 182.792 26.043C183.089 26.0191 183.289 25.6309 183.247 25.189C183.204 24.7471 182.937 24.4127 182.641 24.4426C182.344 24.4724 182.15 24.8486 182.186 25.2906Z"
      fill="#263238"
    />
    <path
      d="M180.389 25.8101C180.389 25.8101 181.637 28.2645 182.867 29.3932C182.14 30.1994 180.758 29.9904 180.758 29.9904L180.389 25.8101Z"
      fill="#FF5652"
    />
    <path
      d="M179.376 32.1402C178.874 32.1816 178.368 32.1197 177.891 31.9583C177.414 31.7969 176.977 31.5395 176.606 31.2026C176.59 31.1916 176.577 31.1771 176.567 31.1603C176.557 31.1435 176.551 31.1247 176.549 31.1053C176.548 31.0859 176.55 31.0664 176.557 31.0482C176.564 31.03 176.575 31.0136 176.589 31C176.604 30.9865 176.621 30.9763 176.639 30.97C176.658 30.9638 176.678 30.9617 176.698 30.9639C176.717 30.9662 176.736 30.9727 176.753 30.983C176.769 30.9933 176.784 31.0071 176.794 31.0235C177.214 31.3867 177.715 31.6473 178.256 31.7843C178.797 31.9212 179.364 31.9306 179.909 31.8117C179.944 31.8047 179.98 31.8114 180.009 31.8304C180.039 31.8494 180.059 31.8792 180.067 31.9133C180.072 31.948 180.064 31.9834 180.044 32.0123C180.023 32.0412 179.993 32.0613 179.958 32.0685C179.765 32.1034 179.571 32.1273 179.376 32.1402Z"
      fill="#263238"
    />
    <path
      d="M175.248 25.4159C175.204 25.4279 175.158 25.4279 175.115 25.4159C175.051 25.3871 175 25.335 174.974 25.2704C174.948 25.2058 174.949 25.1338 174.975 25.0695C175.137 24.685 175.388 24.3437 175.71 24.0738C176.031 23.8039 176.413 23.613 176.824 23.5169C176.858 23.5109 176.893 23.5118 176.927 23.5194C176.961 23.5271 176.993 23.5413 177.021 23.5614C177.049 23.5814 177.073 23.6068 177.091 23.6361C177.11 23.6654 177.122 23.6979 177.127 23.7319C177.137 23.8006 177.121 23.8707 177.08 23.9275C177.039 23.9843 176.978 24.0233 176.909 24.0364C176.585 24.1157 176.284 24.2697 176.033 24.4856C175.781 24.7016 175.585 24.9733 175.46 25.2786C175.44 25.3174 175.409 25.3504 175.372 25.3744C175.335 25.3984 175.292 25.4127 175.248 25.4159Z"
      fill="#263238"
    />
    <path
      d="M184.109 22.9197C184.067 22.9299 184.023 22.9303 183.981 22.921C183.939 22.9116 183.9 22.8928 183.867 22.866C183.625 22.6471 183.334 22.488 183.018 22.4018C182.702 22.3156 182.37 22.3048 182.049 22.3703C181.981 22.3888 181.909 22.3804 181.848 22.3469C181.786 22.3133 181.741 22.2574 181.721 22.1912C181.704 22.1247 181.713 22.0543 181.747 21.9943C181.781 21.9344 181.837 21.8894 181.903 21.8687C182.311 21.7773 182.736 21.7838 183.141 21.8877C183.545 21.9916 183.919 22.1899 184.23 22.4659C184.278 22.5158 184.305 22.5821 184.305 22.651C184.305 22.7199 184.278 22.7862 184.23 22.8361C184.197 22.8731 184.156 22.9017 184.109 22.9197Z"
      fill="#263238"
    />
    <path
      d="M165.159 46.371C156.002 48.6462 153.457 60.1837 153.457 60.1837L164.832 68.2218C164.832 68.2218 167.65 65.3135 168.498 58.4878C168.904 55.2391 167.013 46.2814 165.159 46.371Z"
      fill="#263238"
    />
    <path
      d="M163.748 90.0966L169.039 92.0434L164.991 97.418C164.991 97.418 160.415 95.083 161.276 92.4853L163.748 90.0966Z"
      fill="#FF8B7B"
    />
    <path
      d="M171.499 97.2806L169.76 98.9228C169.425 99.2386 168.987 99.4269 168.524 99.4542C168.062 99.4816 167.604 99.3462 167.233 99.0721L164.973 97.394L169.021 92.0194L171.536 94.5037C171.723 94.6856 171.872 94.903 171.972 95.143C172.072 95.3829 172.122 95.6403 172.119 95.8998C172.115 96.1592 172.059 96.4153 171.952 96.6526C171.846 96.8899 171.692 97.1035 171.499 97.2806Z"
      fill="#FF8B7B"
    />
    <path
      d="M223.873 60.8049L223.224 57.8906C223.157 57.59 222.98 57.3239 222.729 57.1415C222.477 56.9592 222.166 56.8732 221.855 56.8993C220.916 56.9775 220.006 57.2632 219.195 57.7354L215.559 59.8613L215.88 63.2414C217.389 65.5345 222.103 63.4802 222.103 63.4802L222.491 63.3309C222.99 63.1419 223.403 62.7833 223.657 62.3199C223.91 61.8566 223.987 61.3191 223.873 60.8049Z"
      fill="#FF8B7B"
    />
    <path
      d="M215.093 61.1631C214.869 60.0225 215.372 59.1028 215.257 58.0279C215.16 57.0904 215.415 56.0393 215.112 54.7613C215.475 54.1642 216.79 54.6539 216.808 56.2364C217.214 57.8607 219.026 57.6278 219.748 57.4307C219.748 57.4487 217.299 61.6468 215.093 61.1631Z"
      fill="#FF8B7B"
    />
  </svg>
);

const Success = (props) => {
  return (
    <>
      <Box {...props}>{svg}</Box>
    </>
  );
};

export default Success;
