import React from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import { createPatient } from '@api/main';
import { GenderEnum, CountryEnum, BookingStepId } from '@utils/constants';
import { enumToSelectOptions } from '@utils/common';
import NextStepLayoutWrapper from '@components/NextStepLayoutWrapper';
import { FormInputText, FormDatePicker, FormSelect, PhoneInput } from '@components/Input';
import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

const CreatePatient = ({ setData, setCurrentStep }) => {
  const showToast = useToast();

  const defaultValues = {
    fullName: '',
    phoneNumber: '',
    email: '',
    address: '',
    heightInCm: 0,
    weightInKg: 0,
    medicalHistory: '',
    allergy: '',
    gender: GenderEnum.Nam,
    birthdayUnix: moment('1990-01-01'),
    countryId: CountryEnum['Việt Nam']
  };

  const createPatientSchema = yup.object().shape({
    fullName: yup.string().required().max(128).trim(),
    birthdayUnix: yup.date().required(),
    phoneNumber: yup.string().required().max(16),
    email: yup.string().email().trim(),
    address: yup.string().required().max(128).trim(),
    gender: yup.number().required(),
    heightInCm: yup.number().min(0),
    weightInKg: yup.number().min(0),
    medicalHistory: yup.string().trim(),
    allergy: yup.string().trim()
  });

  const { handleSubmit, control, formState } = useForm({
    mode: 'all',
    resolver: yupResolver(createPatientSchema),
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      const result = await createPatient({
        ...data,
        birthdayUnix: new Date(data.birthdayUnix).getTime()
      });
      if (!result?.metadata || !result?.metadata.success) return;
      const patient = _.pick(result.data, ['patientId', 'fullName', 'birthdayUnix']);
      setData((currentValue) => ({ ...currentValue, patientId: patient.patientId, patient }));
      setCurrentStep(BookingStepId.createAppointment);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <NextStepLayoutWrapper
      headerContent="Tạo hồ sơ mới"
      prevStep={() => setCurrentStep(BookingStepId.selectPatient)}
      nextStep={handleSubmit(onSubmit)}
      isNextStep={formState.isValid}
    >
      <FormProvider>
        <Grid container flexDirection="row" padding="1rem" rowGap="12px" columnGap="8px">
          <Grid item width="100%">
            <FormInputText name="fullName" label="Họ và tên" placeholder="Họ và tên" control={control} isRequired />
          </Grid>
          <Grid item width="100%">
            <FormDatePicker name="birthdayUnix" label="Ngày sinh" control={control} isRequired maxDate={moment()} />
          </Grid>
          <Grid item width="calc(30% - 4px)">
            <FormSelect
              name="gender"
              label="Giới tính"
              control={control}
              options={enumToSelectOptions(GenderEnum)}
              isRequired
            />
          </Grid>
          <Grid item width="calc(70% - 4px)">
            <PhoneInput
              name="phoneNumber"
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              control={control}
              isRequired
            />
          </Grid>
          <Grid item width="100%">
            <FormInputText name="address" label="Địa chỉ" placeholder="Nhập địa chỉ" control={control} isRequired />
          </Grid>
          <Grid item width="100%">
            <FormSelect
              name="countryId"
              label="Quốc tịch"
              control={control}
              options={enumToSelectOptions(CountryEnum)}
              isRequired
            />
          </Grid>
          <Grid item width="100%">
            <FormInputText name="email" label="Email" placeholder="Nhập email" control={control} />
          </Grid>
          <Grid item width="100%">
            <FormInputText
              type="number"
              name="heightInCm"
              label="Chiều cao (cm)"
              placeholder="Nhập chiều cao"
              control={control}
            />
          </Grid>
          <Grid item width="100%">
            <FormInputText
              type="number"
              name="weightInKg"
              label="Cân nặng (kg)"
              placeholder="Nhập cân nặng"
              control={control}
            />
          </Grid>
          <Grid item width="100%">
            <FormInputText
              name="medicalHistory"
              label="Lịch sử bệnh lý"
              placeholder="Nhập lịch sử bênh lý"
              control={control}
            />
          </Grid>
          <Grid item width="100%">
            <FormInputText name="allergy" label="Dị ứng" placeholder="Nhập dị ứng cá nhân" control={control} />
          </Grid>
        </Grid>
      </FormProvider>
    </NextStepLayoutWrapper>
  );
};

export default CreatePatient;
