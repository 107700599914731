import { refreshToken } from '@api/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STORAGE_KEYS, Storage } from '@utils/storage';

const initialState = {
  isLoggedIn: false,
  userData: null
};

// ** Check remenbered user
export const checkRememberedUserAC = createAsyncThunk('auth/CHECK_REMENBERED_USER_DONE', async () => {
  try {
    if (!!Storage.getItem(STORAGE_KEYS.userData) && !!Storage.getItem(STORAGE_KEYS.token)) {
      await refreshToken();
      return {
        isLoggedIn: true,
        userData: Storage.getItem(STORAGE_KEYS.userData)
      };
    }
    return {
      isLoggedIn: false,
      userData: null
    };
  } catch (error) {
    return {
      isLoggedIn: false,
      userData: null
    };
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginMB: (state) => {
      state.isLoggedIn = true;
    },
    LOGIN: (state, action) => ({
      ...state,
      isLoggedIn: true,
      userData: action.payload.user
    })
  },
  extraReducers(builder) {
    builder.addCase(checkRememberedUserAC.fulfilled, (state, action) => ({
      ...state,
      isCheckingRememberedUser: false,
      isLoggedIn: action.payload?.isLoggedIn,
      userData: action.payload?.user
    }));
  }
});

// Action creators are generated for each case reducer function
export const { loginMB, LOGIN } = authSlice.actions;

export const loginAC = (data) => {
  // ** Add to user, accessToken & refreshToken to localStorage
  Storage.setItem(STORAGE_KEYS.userData, data.user);
  Storage.setItem(STORAGE_KEYS.token, data.token);

  return LOGIN(data);
};

export default authSlice.reducer;
