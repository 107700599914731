import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Trường bắt buộc nhập',
    notType: 'Giá trị không hợp lệ'
  },
  string: {
    default: 'Giá trị không hợp lệ',
    email: 'Email không đúng định dạng',
    min: 'Vui lòng nhập tối thiểu ${min} ký tự',
    max: 'Vui lòng nhập tối đa ${max} ký tự'
  },
  number: {
    default: 'Giá trị không hợp lệ',
    min: 'Giá trị phải lớn hơn hoặc bằng ${min}',
    max: 'Giá trị phải nhỏ hơn hoặc bằng ${max}',
    integer: 'Giá trị không hợp lệ'
  }
});
