import React, { useState } from 'react';
import { FormLabel, TextField } from '@mui/material';

import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import { FormControlWrapper, InputWrapper } from '@components/Input';
import AuthLayout from '@components/AuthLayout';
import { BookingSteps } from '@utils/constants';
import { confirmOtp } from 'src/config/firebaseConfig';
import { LoadingButton } from '@mui/lab';

const Register1 = ({ clinicInfo, authData, setAuthData, setCurrentStep }) => {
  const showToast = useToast();

  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const auth = await confirmOtp(authData.confirm, code);
      if (!!auth?.token) {
        setAuthData({ ...authData, token: auth.token });
        setCurrentStep(BookingSteps.register2);
      } else {
        showToast({
          type: 'error',
          message: 'Mã không hợp lệ'
        });
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout clinicInfo={clinicInfo}>
      <form style={{ display: 'flex', flexDirection: 'column', height: '100%' }} onSubmit={onSubmit}>
        <FormControlWrapper required style={{ width: '100%', marginTop: '2rem' }}>
          <FormLabel>Mã OTP</FormLabel>
          <InputWrapper hasBorder>
            <TextField autoFocus type="number" onChange={(e) => setCode(e.target.value)} value={code} fullWidth />
          </InputWrapper>
        </FormControlWrapper>

        <LoadingButton
          id="recaptcha-container"
          sx={{ marginTop: '2rem', paddingY: '10px' }}
          variant="contained"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!code}
        >
          Tiếp theo
        </LoadingButton>
      </form>
    </AuthLayout>
  );
};

export default Register1;
