import React from 'react';
import { Typography, Grid, Container, Avatar, Box } from '@mui/material';

import defaultAvatar from '@assets/avatar-blank.png';

const AuthLayout = ({ clinicInfo, children }) => {
  return (
    <Container>
      <Grid container direction="column" paddingTop="2.75rem" paddingX="1rem">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            variant="square"
            sx={{ width: 160, height: 160, marginBottom: 2, textAlign: 'center', display: 'inline-block' }}
            src={clinicInfo?.logo?.fileUrl || defaultAvatar}
            alt={clinicInfo?.clinicName || 'Phòng khám 4.0'}
          />
        </Box>
        <Typography variant="h2" marginBottom="6px" color="textPrimary.main" textAlign="center">
          {clinicInfo?.clinicName || 'Phòng khám 4.0'}
        </Typography>
        {/* <Typography variant="h5" lineHeight="30px" color="textPrimary.main">
          Dr. Cloud hỗ trợ đặt lịch khám đa nền tảng một cách thuận tiện nhất, tối ưu nhất
        </Typography>
        <Link href="https://drcloud.vn/" variant="h5" color="primary" underline="hover" width="max-content">
          Tìm hiểu về Dr. Cloud
        </Link> */}

        {children}
      </Grid>
    </Container>
  );
};

export default AuthLayout;
