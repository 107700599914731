import { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { checkPaymentWebhook } from '@api/main';

const RedirectFromGateway = () => {
  const [mode, setMode] = useState();
  const [searchParams] = useSearchParams();

  useEffect(async () => {
    try {
      const paymentData = {
        errorCode: Number(searchParams.get('errorCode')),
        message: searchParams.get('message'),
        partnerCode: searchParams.get('partnerCode'),
        apiKey: searchParams.get('apiKey'),
        amount: Number(searchParams.get('amount')),
        currency: searchParams.get('currency'),
        orderId: searchParams.get('orderId'),
        bankCode: searchParams.get('bankCode'),
        paymentMethod: searchParams.get('paymentMethod'),
        paymentType: searchParams.get('paymentType'),
        appotapayTransId: searchParams.get('appotapayTransId'),
        transactionTs: Number(searchParams.get('transactionTs')),
        extraData: searchParams.get('extraData'),
        signature: searchParams.get('signature')
      };

      await checkPaymentWebhook(paymentData);
      setMode('success');
    } catch (error) {
      setMode('failed');
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      /* eslint-disable */
      if (typeof Android !== 'undefined') {
        if (mode === 'success') {
          Android.paymentSuccess(true);
        }

        if (mode === 'failed') {
          Android.paymentSuccess(false);
        }
      } else {
        if (mode === 'success') {
          window.ReactNativeWebView.postMessage('success');
        }

        if (mode === 'failed') {
          window.ReactNativeWebView.postMessage('failed');
        }
      }
    }, 2000);
  }, [mode]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex="1" style={{ overflowY: 'scroll' }}>
        <Grid container direction="column" height="100%">
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            {!!mode && mode === 'success' && (
              <>
                <CheckCircleIcon sx={{ fontSize: 160, width: '100%' }} color="primary" />
                <Typography
                  sx={{ display: 'flex', justifyContent: 'center', mt: '0.5rem', fontSize: '24px' }}
                  color="textPrimary.main"
                >
                  Thanh Toán Thành Công
                </Typography>
              </>
            )}
            {!!mode && mode === 'failed' && (
              <>
                <HighlightOffIcon sx={{ fontSize: 160, width: '100%' }} color="error" />
                <Typography
                  sx={{ display: 'flex', justifyContent: 'center', mt: '0.5rem', fontSize: '24px' }}
                  color="textPrimary.main"
                >
                  Thanh Toán Thất Bại
                </Typography>
              </>
            )}
          </Container>
        </Grid>
      </Box>
    </Box>
  );
};

export default RedirectFromGateway;
