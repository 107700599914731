import axios, { handleRefreshToken } from './base';

export const getUserInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
};

export const authLoginToken = (loginToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/sign-in-with-mb`, { loginToken });
};

export const searchClinics = async (params) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/search`, { params });
};

export const getPatients = async (pageSize, pageNumber) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/patients?pageSize=${pageSize}&pageNumber=${pageNumber}`);
};

export const getAllPatientsAPI = async (pageNumber = 1) => {
  let arr = [];
  const apiRes = await getPatients(100, pageNumber);
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData);
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await getAllPatientsAPI(pageNumber + 1);
      arr = [...arr, ...nextPageRes];
    }
  }
  return arr;
};

export const createPatient = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/patients`, data);
};

export const getClinicInfo = async (clinicId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/info-for-booking`);
};

export const getClinicInfoBySlug = async (clinicSlug) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/${clinicSlug}/info-for-booking-web`);
};

export const getDoctorInfo = async (clinicId, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/get-doctor-for-booking`, data);
};

export const getDoctorSchedule = async (clinicId, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/get-doctor-schedule`, data);
};

export const createAppointment = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/appointments`, data);
};

export const checkPaymentWebhook = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/orders/payment-webhook`, data);
};

export const checkPhoneNumberAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/check-phone-number`, data, {
    __auth: false
  });
};

export const registerPatientAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/register-patient-v2?lang=${lang}`, data, {
    __auth: false
  });
};

export const loginAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { __auth: false });
};

export const verifyPatientAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-patient-v2`, data, {
    __auth: false
  });
};

export const forgotPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password-v2?lang=${lang}`, data);
};

export const resetPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-patient-password?lang=${lang}`, data, {
    __auth: false
  });
};

export const refreshToken = async () => handleRefreshToken();
